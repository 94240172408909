import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from './components/ScrollToTop';
import loadable from '@loadable/component';
import "@google/model-viewer/dist/model-viewer";

const Home = loadable(() => import('./pages/Home'));
const About = loadable(() => import('./pages/About'));
const Contact = loadable(() => import('./pages/Contact'));
const Designs = loadable(() => import('./pages/Designs'));
const Printing = loadable(() => import('./pages/Printing'));
const Scanning = loadable(() => import('./pages/Scanning'));
const Upload = loadable(() => import('./pages/Upload'));
const NotFound = loadable(() => import('./pages/NotFound'));
const WorkPage = loadable(() => import('./pages/WorkPage'));
const FAQ = loadable(() => import('./pages/FAQ'));
const Filament = loadable(() => import('./pages/Filament'));

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

function getColor(options) {
  switch (options.type) {
    case 'info':
      return 'bg-blue-500';
    case 'error':
      return 'bg-red-500';
    case 'success':
      return 'bg-green-500';
    default:
      return '';
  }
}

function getText(options) {
  switch (options.type) {
    case 'info':
      return "INFO";
    case 'error':
      return "ERROR";
    case 'success':
      return "SUCCESS";
    default:
      return '';
  }
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div className={`p-6 ${getColor(options)} text-white font-semibold rounded-2xl flex justify-between items-center`} style={style}>
    <h3 className="p-2 mr-4">
      <span className="font-bold">{getText(options)}</span><br/>{message}
    </h3>
    <FontAwesomeIcon className="hover:cursor-pointer" icon={faClose} onClick={close}/>
  </div>
)

function App() {
  return (
    <BrowserRouter>
      <Helmet defaultTitle="3D Printing Canberra: ">
        <html lang="en" amp />
        <title>3D Printing Canberra: Your one stop-shop for 3D printing services in ACT & surrounds</title>
        <meta name="description" content="We are a local, home-grown Canberra based 3D printing & design studio offering a range of high quality and affordable 3D printing services to the Canberra region & surrounds." />
        <meta name="theme-color" content="#000000"/>
        <meta name="keywords" content="3D Printing Canberra, 3d, printing, canberra, 3d printing canberra, 3DPrintingCanberra, filament, custom designs, scanning, services, 3d printing services, Canberra, PETG, ABS, ASA, Resin, Wood"></meta>
      </Helmet>

      <Root/>
    </BrowserRouter>
  );
}

const routes = () => {
  return (
    <>
      <Route index path="/" element={<Home/>} />
      <Route path="about" element={<About/>} />
      <Route path="upload" element={<Upload/>} />
      <Route path="contact" element={<Contact/>} />
      <Route path="work" element={<WorkPage/>} />
      <Route path="services/designs" element={<Designs/>}/>
      <Route path="services/printing" element={<Printing/>}/>
      <Route path="services/scanning" element={<Scanning/>}/>
      <Route path="work/examples" element={<WorkPage/>}/>
      <Route path="work/filament" element={<Filament/>}/>
      <Route path="contact/form" element={<Contact/>}/>
      <Route path="contact/upload" element={<Upload/>}/>
      <Route path="contact/faq" element={<FAQ/>}/>
    </>
  )
}

function Root() {
  return (
    <AnimatePresence>
      <AlertProvider template={AlertTemplate} {...options}>
        <ParallaxProvider>
          <ScrollToTop/>
          <Routes>
            { routes() }
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </ParallaxProvider>
      </AlertProvider>
    </AnimatePresence>
  )
}

export default App;